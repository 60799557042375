import Link from 'next/link';

import { Button } from '@/shared/components/ui/button';
import { CardBlock } from '@/shared/components/ui/card-block';
import { Title } from '@/shared/components/ui/title';
import { Wrapper } from '@/shared/components/wrapper';

export const Services = () => {
  return (
    <Wrapper>
      <div className="md:mt-15 mt-10 space-y-4 xl:mt-20" id="services">
        <Title type="h2">Услуги</Title>

        <div className="flex flex-col items-start gap-3 md:flex-row md:items-stretch md:gap-5">
          <CardBlock>
            <Title type="h3">Доставка</Title>

            <p>
              Индивидуально обсудим условия. Отправим любой транспортной компанией в&nbsp;любую точку России любой товар
              из каталога.
            </p>

            <Button variant="secondary" className="self-start" asChild>
              <Link href="#contacts">Связаться с нами</Link>
            </Button>
          </CardBlock>

          <CardBlock>
            <Title type="h3">Проведение натурных испытаний</Title>

            <p>
              Индивидуально обсудим условия. Отправим любой транспортной компанией в&nbsp;любую точку России любой товар
              из каталога.
            </p>

            <Button variant="secondary" className="self-start" asChild>
              <Link href="#contacts">Связаться с&nbsp;нами</Link>
            </Button>
          </CardBlock>
        </div>
      </div>
    </Wrapper>
  );
};
