import { createQuery } from '@farfetched/core';
import { createEvent, sample } from 'effector';
import { not } from 'patronum';

import { findRandomQuoteFx } from '@/shared/api/quotes/api';

export const randomQuoteQuery = createQuery({
  name: 'randomQuoteQuery',
  effect: findRandomQuoteFx,
});

export const updateRandomQuoteQuery = createEvent();

sample({
  clock: updateRandomQuoteQuery,
  filter: not(randomQuoteQuery.$pending),
  target: randomQuoteQuery.refresh,
});
