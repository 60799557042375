import Link from 'next/link';

import ArrowDownRightIcon from '@/shared/components/icons/arrow-down-right';
import { Button } from '@/shared/components/ui/button';
import { Wrapper } from '@/shared/components/wrapper';

export const Footer = () => {
  return (
    <div className="mt-[40px] w-full bg-[#DBE6F0] p-5 xl:mt-[100px] xl:p-9">
      <Wrapper className="flex flex-col items-start justify-between gap-3">
        <div className="flex w-full items-baseline justify-between">
          <p className="text-2xl font-bold capitalize">Азимут</p>

          <div className="hidden items-center justify-center gap-3 md:flex">
            <Button variant="outline" asChild>
              <Link href="/catalog">
                Смотреть каталог
                <ArrowDownRightIcon className="ml-2 h-4 w-4" />
              </Link>
            </Button>

            <Button variant="outline" asChild>
              <Link href="#contacts">Связаться с нами</Link>
            </Button>
          </div>

          <p>2024 (C)</p>
        </div>

        <div className="flex flex-col items-start justify-center gap-3 md:hidden">
          <Button variant="outline" asChild>
            <Link href="/catalog">
              Смотреть каталог
              <ArrowDownRightIcon className="ml-2 h-4 w-4" />
            </Link>
          </Button>

          <Button variant="outline" asChild>
            <Link href="#contacts">Связаться с нами</Link>
          </Button>
        </div>
      </Wrapper>
    </div>
  );
};
