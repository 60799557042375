import Link from 'next/link';

import MailIcon from '@/shared/components/icons/mail';
import PhoneIcon from '@/shared/components/icons/phone';
import TelegramIcon from '@/shared/components/icons/telegram';
import WhatsAppIcon from '@/shared/components/icons/whats-app';
import { Button } from '@/shared/components/ui/button';
import { Title } from '@/shared/components/ui/title';
import { Wrapper } from '@/shared/components/wrapper';

export const Contacts = () => {
  return (
    <Wrapper>
      <div className="md:mt-15 mt-10 space-y-4 xl:mt-20" id="contacts">
        <Title type="h2">Контакты</Title>

        <p>
          Позвоните или напишите нам, и&nbsp;мы&nbsp;поможем найти самый подходящий товар, проверим наличие
          на&nbsp;складе и&nbsp;оформим доставку.
        </p>

        <div className="flex flex-row flex-wrap items-start gap-3 md:gap-5">
          <Button asChild>
            <Link href="https://web.whatsapp.com/">
              <WhatsAppIcon className="mr-2 h-4 w-4" />
              WhatsApp
            </Link>
          </Button>

          <Button asChild>
            <Link href="https://www.telegram.org">
              <TelegramIcon className="mr-2 h-4 w-4" />
              Telegram
            </Link>
          </Button>

          <Button variant="secondary" asChild>
            <Link href="tel:89095379940">
              <PhoneIcon className="mr-2 h-4 w-4" />
              +7 (909) 537 99 40
            </Link>
          </Button>

          <Button variant="secondary" asChild>
            <Link href="mailto:ems5555@list.ru">
              <MailIcon className="mr-2 h-4 w-4" />
              ems5555@list.ru
            </Link>
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};
