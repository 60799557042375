import Image from 'next/image';

import { CustomSwiper } from '@/shared/components/custom-swiper';
import { Title } from '@/shared/components/ui/title';
import { Wrapper } from '@/shared/components/wrapper';

export const Brands = () => {
  const brandNames = ['elementa', 'fischer', 'koelner', 'rawlplug', 'hilti', 'cuttori'];

  return (
    <Wrapper>
      <div className="md:mt-15 mt-10 space-y-4 xl:mt-20">
        <Title type="h2">Продаем товары брендов</Title>

        <CustomSwiper
          id="brands-swiper"
          breakpoints={{
            768: {
              slidesPerView: 3,
            },
            0: {
              slidesPerView: 2,
            },
          }}
        >
          {brandNames.map((brandName) => (
            <Image
              key={brandName}
              src={`/images/brands/brands-${brandName}.png`}
              alt={`${brandName} brand`}
              width={400}
              height={65}
              className="max-h-[40px] object-contain md:max-h-[65px]"
            />
          ))}
        </CustomSwiper>
      </div>
    </Wrapper>
  );
};
