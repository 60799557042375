import { useState } from 'react';
import Link from 'next/link';

import MenuIcon from '@/shared/components/icons/menu';
import XIcon from '@/shared/components/icons/x-sign';
import { Button } from '@/shared/components/ui/button';
import { Wrapper } from '@/shared/components/wrapper';

import { NAV_LINKS } from './constants';

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="bg-[#5A87F2]">
      <header className="block rounded-b-xl bg-[#DBE6F0] md:hidden">
        <Wrapper className="flex items-center justify-between py-4">
          <p className="text-2xl font-bold capitalize">Азимут</p>

          <button
            onClick={() => setIsMenuOpen(true)}
            className="rounded-md p-2 transition-colors hover:bg-gray-200"
            aria-label="Open menu"
          >
            <MenuIcon />
          </button>
        </Wrapper>
      </header>

      {isMenuOpen && (
        <div className="fixed inset-0 z-[9999] bg-[#DBE6F0]">
          <Wrapper className="flex h-full flex-col items-end justify-between py-4">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="rounded-md p-2 transition-colors hover:bg-gray-200"
              aria-label="Close menu"
            >
              <XIcon />
            </button>

            <div className="mb-10 flex w-full flex-col items-end gap-8">
              <nav className="flex w-full flex-col items-end gap-8 text-xl">
                {NAV_LINKS.map((link) => (
                  <Button variant="ghost" key={link.href} asChild>
                    <Link href={link.href} className="underline" onClick={() => setIsMenuOpen(false)}>
                      {link.text}
                    </Link>
                  </Button>
                ))}
              </nav>

              <Button variant="ghost" asChild>
                <Link href="tel:+79095379940" className="text-xl underline">
                  +7 (909) 537 99 40
                </Link>
              </Button>
            </div>
          </Wrapper>
        </div>
      )}

      <header className="hidden pt-10 md:block">
        <Wrapper className="flex items-center justify-between rounded-xl bg-[#DBE6F0] px-5 py-3 lg:px-7 lg:py-5">
          <p className="text-2xl font-bold capitalize">Азимут</p>

          <div className="flex gap-5">
            {NAV_LINKS.map((link) => (
              <Button variant="ghost" asChild key={link.href}>
                <Link href={link.href}>{link.text}</Link>
              </Button>
            ))}
          </div>

          <Link href="tel:+79095379940">+7 (909) 537 99 40</Link>
        </Wrapper>
      </header>
    </div>
  );
};
