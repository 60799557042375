import Image from 'next/image';

import { CustomSwiper } from '@/shared/components/custom-swiper';
import ArrowDownRightIcon from '@/shared/components/icons/arrow-down-right';
import { Button } from '@/shared/components/ui/button';
import { Title } from '@/shared/components/ui/title';
import { Wrapper } from '@/shared/components/wrapper';

import { SLIDER_IMAGES } from './constants';

export const Main = () => {
  return (
    <div className="w-full space-y-5 rounded-b-xl bg-secondary py-10">
      <Wrapper>
        <div className="space-y-3">
          <Title type="h1" className="text-[#F8FAFC]">
            <div className="flex items-center gap-2">
              <span className="block w-full italic">Качественный</span>
              <div className="hidden h-2 w-full max-w-[300px] rounded-full bg-[#F8FAFC] 2xl:block" />
            </div>
            <span className="block 2xl:text-right">строительный</span>
            <span className="block 2xl:text-right">крепёж</span>
          </Title>
          <Image
            src={'/images/background/house.svg'}
            alt={'house'}
            width={350}
            height={350}
            className="absolute top-[350px] hidden 2xl:block"
          />
          <Image
            src={'/images/background/house.svg'}
            alt={'house'}
            width={250}
            height={250}
            className="absolute right-[25vw] top-[330px] hidden lg:block 2xl:hidden"
          />
          <Image
            src={'/images/background/house.svg'}
            alt={'house'}
            width={250}
            height={250}
            className="absolute right-[20vw] top-[250px] hidden md:block lg:hidden"
          />
          <Image
            src={'/images/background/house.svg'}
            alt={'house'}
            width={160}
            height={160}
            className="absolute right-[20vw] top-[180px] hidden sm:block md:hidden"
          />
          <Image
            src={'/images/background/house.svg'}
            alt={'house'}
            width={120}
            height={120}
            className="absolute right-[15vw] top-[200px] block sm:hidden"
          />
        </div>
        <div className="mt-8 flex flex-col gap-3 lg:flex-row">
          <CustomSwiper
            id="main-swiper"
            className="w-full lg:max-w-[70%]"
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
              0: {
                slidesPerView: 2,
              },
            }}
            theme="dark"
          >
            {SLIDER_IMAGES.map((src, index) => (
              <Image
                key={index}
                src={src}
                alt={`Slide ${index + 1}`}
                width={300}
                height={300}
                className="h-full w-full rounded-md object-cover md:max-h-[300px] md:max-w-[300px] xl:max-h-[340px] xl:max-w-[340px]"
              />
            ))}
          </CustomSwiper>

          <div className="flex flex-col items-start justify-between gap-3 md:flex-row lg:max-w-[30%] lg:flex-col lg:items-end">
            <p className="text-secondary-foreground md:max-w-[60%] lg:max-w-full lg:text-right">
              Азимут&nbsp;&mdash; проверенный магазин по&nbsp;продаже крепежа, техники и&nbsp;инструментов. Все товары
              в&nbsp;наличии с&nbsp;доставкой любой транспортной компанией.
            </p>

            <Button>
              Смотреть каталог
              <ArrowDownRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
