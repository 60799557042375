import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

import { PageSeo } from '@/shared/lib/seo';

import { Brands } from './brands/brands';
import { Contacts } from './contacts/contacts';
import { Main } from './main';
import { Services } from './services/services';

export const HomePage = () => {
  return (
    <>
      <PageSeo
        title="Азимут фикс - надежный магазин строительного крепежа"
        description="Aims for developers who really care about code quality, architecture, security and all the best practices in frontend"
      />
      <Header />
      <Main />
      <Brands />
      <Services />
      <Contacts />
      <Footer />
    </>
  );
};
